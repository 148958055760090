document.addEventListener("DOMContentLoaded", function () {
    documentReady();
});


function documentReady() {

    if (typeof jQuery == 'undefined') return;
    //InIt
    setupHelp();
    editorsSetup();
    dateControlSetup();

    //Events
    $('.ViewImage').colorbox({ onComplete: function () { $.colorbox.resize(); $('#cboxClose').remove(); } });
}
//Init Functions

//Setup Help
function setupHelp() {
    $('.rtHelpButton').attr('href', 'javascript:void(0);');
    $('.rtHelpButton').attr('target', '');
    $('.rtHelpMessage').click(function () { $(this).hide(); });
}

//Functions
//Grid
function onRowDropping(sender, args, senderId, destId1, destId2, recycleBinId1, recycleBinId2, alertText, canSort) {
    if (sender.get_id() == senderId) {
        var node = args.get_destinationHtmlElement();
        if (isChildOf(senderId, node) || isChildOf(destId1, node) || isChildOf(destId2, node)) {
        }
        else if (isChildOf(recycleBinId1, node)) {
            if (confirm(alertText)) { args.set_destinationHtmlElement($get(recycleBinId1)); }
            else { args.set_cancel(true); }
        }
        else if (isChildOf(recycleBinId2, node)) {
            if (confirm(alertText)) { args.set_destinationHtmlElement($get(recycleBinId2)); }
            else { args.set_cancel(true); }
        }
        else {
            args.set_cancel(true);
        }
    }
    else {
        args.set_cancel(true);
    }
}

//Misc
function isChildOf(parentId, element) {
    if (parentId == "") { return false; }
    while (element) {
        if (element.id && element.id.indexOf(parentId) > -1) {
            return true;
        }
        element = element.parentNode;
    }
    return false;
}


//Help
function rtHelpOpen(HelpMessage, LinkId) {
    $('#' + HelpMessage).text('Loading....');
    $('#' + HelpMessage).show();
    $('#' + HelpMessage).load('/help/summary/' + LinkId);
}

var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;                       // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera) 
var isFirefox = typeof InstallTrigger !== 'undefined';                                           // Firefox 1.0+ 
var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;    // At least Safari 3+: "[object HTMLElementConstructor]" 
var isChrome = !!window.chrome && !isOpera;                                                      // Chrome 1+ 
var isIE = /*@cc_on!@*/false || document.documentMode;

function FixPTags(content) {

    while (content.endsWith("<br>")) { content = content.substring(0, content.length - 4); }
    while (content.endsWith("<br />")) { content = content.substring(0, content.length - 6); }

    //    content = content.replace(/<p(>| [^>]*>)&nbsp;/gmi, "<p$1"); //remove non breaking spaces
    //    content = content.replace(/<p(>| [^>]*>)[\s\t\n\r]*/gmi, "<p$1"); //remove white space
    //    content = content.replace(/[\s\t\n\r]*<\/p>/gmi, "</p>"); //remove white space
    //    content = content.replace(/<\/p>[\s\t\n\r]*<p(>| [^>]*>)/gmi, "</p><p$1"); //remove white space between end and start p tags

    //    content = content.replace(/<\/p>/gmi, "<p>"); //remove any end p tags
    //    content = content.replace(/<p(>| [^>]*>)/gmi, "</p><p$1"); //add end p tags before all start p tags

    //    content = content.replace(/^\s*<p(>| [^>]*>)/gmi, "<p$1"); //remove white space before starting p tag
    //    content = content.replace(/<\/p>\s*$/gmi, "</p>"); //remove white space after ending p tag

    //    if (!content.startsWith("<p>") && !content.startsWith("<p ")) { content = "<p>" + content; } //add missing start p tag
    //    if (!content.endsWith("</p>")) { content = content + "</p>"; } //add missing end p tag

    //    content = content.replace(/<p(>| [^>]*>)[\s\t\n\r]*/gmi, "<p$1"); //remove white space
    //    content = content.replace(/[\s\t\n\r]*<\/p>/gmi, "</p>"); //remove white space

    //    content = content.replace(/<\/p><p><\/p><p(>| [^>]*>)/gmi, "</p><p$1"); //remove unwanted empty p tags
    //    content = content.replace(/^<p><\/p><p(>| [^>]*>)/gmi, "<p$1"); //remove unwanted empty p tags at start
    //    content = content.replace(/<\/p><p><\/p>$/gmi, "</p>"); //remove unwanted empty p tags at end

    //    content = content.replace(/<p(>| [^>]*>)<\/p>/gmi, "<p$1&nbsp;</p>"); //fix empty p tags

    return content;
}

// For xml date+time;
Date.prototype.toXML = function () {
    return (this.toXMLDate() + "T" + this.toXMLTime());
}

// For xml date;
Date.prototype.toXMLDate = function () {
    return this.getFullYear() + "-" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "-" + ((this.getDate() < 10) ? "0" : "") + this.getDate();
}

// For xml time;
Date.prototype.toXMLTime = function () {
    return this.timeNow();
}

// For todays date;
Date.prototype.today = function () {
    return ((this.getDate() < 10) ? "0" : "") + this.getDate() + "/" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "/" + this.getFullYear();
}

// For the time now
Date.prototype.timeNow = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes();
}

// For the time now
Date.prototype.timeNowSeconds = function () {
    return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" + ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
}

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

String.prototype.endsWith = function (str) {
    return (this.match(str + "$") == str)
}

String.prototype.startsWith = function (str) {
    return (this.match("^" + str) == str)
}

String.prototype.trim = function () {
    return (this.replace(/^[\s\xA0]+/, "").replace(/[\s\xA0]+$/, ""))
}

String.prototype.replaceAll = function (search, replacement) {
    return this.split(search).join(replacement);
};

Object.defineProperties(Date, {
    minValue: { value: -8640000000000000 }, // A number, not a date
    maxValue: { value: 8640000000000000 }
});

//Url
function addTrailingDirSlash(url) {
    if (url == "") { return "" }
    url = url.replace(/\/\/+/gi, "/").toLowerCase();
    if (url.charAt(0) == '/') { url = url.substring(1) }
    if (url == "" || url == "/") { return "" }
    var urlParts = url.split("/");
    if (urlParts[urlParts.length - 1].indexOf(".") !== -1) { return url }
    return (url + "/").replace(/\/\/+/gi, "/");
}

//ControlBaseEx
Element.prototype.active = function (state) {
    if (state) {
        $(this).show();
    }
    else {
        $(this).hide();
    }
    return this.validationActive(state);
};

Element.prototype.validationActive = function (state) {
    var i;
    for (i = 0; i < Page_Validators.length; i++) {
        var eId = this.id;
        var vId = Page_Validators[i].id;
        if (vId.startsWith(eId)) { ValidatorEnableNoValidate(Page_Validators[i], state); }
    }
    return this;
};

function ValidatorEnableNoValidate(val, enable) {
    val.enabled = (enable != false);
}

function ToggleRadRadioButtonEx(val) {
    var button = $find(val);
    button.set_checked(!button.get_checked());
}

//RadEditor
function editorsSetup() {

    $(".rtRECView").click(function () {
        $(this).parent().parent().find(".rtRECEdit").show();
        $(this).parent().parent().find(".rtRECView").hide();
        $(this).parent().find(".rtRECEditbtn").hide();
        $(this).parent().find(".rtRECViewbtn").show();
        $find($(this).parent().find(".RadEditor").attr('id')).setFocus();
        moveCaretToEndRadEditor($find($(this).parent().find(".RadEditor").attr('id')));
    });

    $(".rtRECEditbtn").click(function () {
        $(this).parent().parent().find(".rtRECEdit").show();
        $(this).parent().parent().find(".rtRECView").hide();
        $(this).parent().find(".rtRECEditbtn").hide();
        $(this).parent().find(".rtRECViewbtn").show();
        $find($(this).parent().parent().find(".RadEditor").attr('id')).setFocus();
        moveCaretToEndRadEditor($find($(this).parent().parent().find(".RadEditor").attr('id')));
    });

    $(".rtRECViewbtn").click(function () {
        $(this).parent().parent().find(".rtRECEdit").hide();
        $(this).parent().parent().find(".rtRECView").show();
        $(this).parent().find(".rtRECEditbtn").show();
        $(this).parent().find(".rtRECViewbtn").hide();
        copyRadEditorContent($find($(this).parent().parent().find(".RadEditor").attr('id')), $(this).parent().parent().find(".rtRECView"));
    });

    //TextArea
    $(".rtTACView").click(function () {
        $(this).parent().parent().find(".rtTACEdit").show();
        $(this).parent().parent().find(".rtTACView").hide();
        $(this).parent().find(".rtTACEditbtn").hide();
        $(this).parent().find(".rtTACViewbtn").show();
        $(this).parent().parent().find("textarea").focus();
        moveCaretToEnd($(this).parent().parent().find("textarea"));
    });

    $(".rtTACEditbtn").click(function () {
        $(this).parent().parent().find(".rtTACEdit").show();
        $(this).parent().parent().find(".rtTACView").hide();
        $(this).parent().find(".rtTACEditbtn").hide();
        $(this).parent().find(".rtTACViewbtn").show();
        $(this).parent().parent().find("textarea").focus();
        moveCaretToEnd($(this).parent().parent().find("textarea"));
    });

    $(".rtTACViewbtn").click(function () {
        $(this).parent().parent().find(".rtTACEdit").hide();
        $(this).parent().parent().find(".rtTACView").show();
        $(this).parent().find(".rtTACEditbtn").show();
        $(this).parent().find(".rtTACViewbtn").hide();
        $(this).parent().parent().find(".rtTACView").text($(this).parent().parent().find("textarea").val().replace(/\n/gi, "<br />").replace(/\r/, ""));
        $(this).parent().parent().find(".rtTACView").html($(this).parent().parent().find(".rtTACView").html().replace(/&lt;br \/&gt;/gi, "<br />"));
    });

    //Init
    initTextAreas();
    initRadEditors();
}

function FixRECs() {
    $(".rtRECViewbtn").each(function () {
        $(this).parent().parent().find(".rtRECEdit").hide();
        $(this).parent().parent().find(".rtRECView").show();
        $(this).parent().find(".rtRECEditbtn").show();
        $(this).parent().find(".rtRECViewbtn").hide();
        copyRadEditorContent($find($(this).parent().parent().find(".RadEditor").attr('id')), $(this).parent().parent().find(".rtRECView"));
    });
}

function ToggleTACEditView(target) {
    var btnView = target.parent().parent().find(".rtTACViewbtn");
    var btnEdit = target.parent().parent().find(".rtTACEditbtn");
    if (btnView.is(':visible')) { btnView.click() }
    else { btnEdit.click() }
}

function ToggleRECEditView(target) {
    var btnView = target.parent().parent().find(".rtRECViewbtn");
    var btnEdit = target.parent().parent().find(".rtRECEditbtn");
    if (btnView.is(':visible')) { btnView.click() }
    else { btnEdit.click() }
}


//RadEditor
function initRadEditors() {
    FixRECs();
}

function rtRECHideEditor(editor, args) {
    copyRadEditorContent(editor, editorFindPart(editor));
    editorFindPart(editor, "rtRECEdit").hide();
    var style = editor.get_contentArea().style;
    style.margin = "0";
    style.padding = "8px 10px";
    style.border = "0";
}

function editorFindPart(editor, part) {
    return $("#" + editor.get_id()).parent().parent().find("." + part);
}

function moveCaretToEndRadEditor(editor) {
    //editor.set_html('<p>0123456789abcdefghijklmnop</p><br />');
    //editor.set_html('<p>0123456789abcdefghijklmnop</p>');
    //editor.set_html('0123456789abcdefghijklmnop');
    var content = editor.get_html();
    content = FixPTags(content);
    editor.set_html(content);
    editor.repaint();

    var selectionStart = content.lastIndexOf("</p>");
    if (selectionStart == -1) { selectionStart = content.length; }
    var selectionEnd = selectionStart;

    var iframe = document.getElementById(editor.get_id() + "_contentIframe");
    var iframewin = iframe.contentWindow;
    var input = (iframe.contentDocument || iframe.contentWindow.document).body;
    if (input.setSelectionRange) {
        selectionStart = adjustOffset(input, selectionStart);
        selectionEnd = adjustOffset(input, selectionEnd);
        input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.selection) {
        var range = input.createRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else if (input.createTextRange) {
        var range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else if (editor.get_document().selection) {
        var range = (editor.get_document().selection.createRange());
        var oRange = range.duplicate();
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else if (iframewin.getSelection && isChrome) {//Chrome
        var sel = iframewin.getSelection();
        var range = editor.get_document().createRange();
        var node = range.startContainer;
        var offset = 1;
        node = node.lastChild;
        node = node.lastChild;
        if (node.nodeType != 3) {
            if (node.lastChild && node.lastChild.localName == "p") {
                node = node.lastChild;
            }
            else {
                var tempnode = node.lastChild;
                while (tempnode.previousSibling) {
                    tempnode = tempnode.previousSibling;
                    if (tempnode.localName == "p" || tempnode.nodeType == 3) {
                        node = tempnode;
                        break;
                    }
                }
            }

        }
        if (node.lastChild) { node = node.lastChild; }
        if (node.nodeType == 3) { offset = node.nodeValue.length; }
        range.setStart(node, offset);
        range.setEnd(node, offset);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
    }
    else if (iframewin.getSelection && !isChrome) {//firefox
        var range = iframewin.getSelection().getRangeAt(0);
        var node = range.startContainer;
        var offset = 1;
        if (node.nodeType != 3) {
            if (node.lastChild && node.lastChild.localName == "p") {
                node = node.lastChild;
            }
            else {
                var tempnode = node.lastChild;
                while (tempnode.previousSibling) {
                    tempnode = tempnode.previousSibling;
                    if (tempnode.localName == "p" || tempnode.nodeType == 3) {
                        node = tempnode;
                        break;
                    }
                }
            }
        }
        if (node.nodeType == 3) { offset = node.nodeValue.length; }
        range.setEnd(node, offset);
        range.setStart(node, offset);
        range.collapse(false);
    }
    else if (editor.getSelection) {
        var range = editor.getSelection().getRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
}

function copyRadEditorContent(src) {
    var dest = $("#" + src.get_id() + "_contentIframeViewOnly");
    setIframeDocument(document.getElementById(src.get_id() + "_contentIframeViewOnly"), src.get_html());
    if ($(window).width() < 1024) {
        dest.contents().find('body').css("min-height", "26px");
    }
    else {
        dest.contents().find('body').css("min-height", "20px");
    }
    dest.height("0px");
    dest.contents().find('body').css("padding", "0");
    dest.contents().find('body').css("margin", "0");
    dest.contents().find('#iFrameHtml').css("padding", "8px 10px");
    dest.contents().find('#iFrameHtml').css("margin", "0");
    dest.contents().find('#iFrameHtml').css("cursor", "pointer");
    dest.contents().find('#iFrameHtml').click(function () { dest.click() });
    dest.height(dest.contents().find('body').height() - 2);
}

function setIframeDocument(iframe, content) {
    if (iframe) {
        content = FixPTags(content);
        var iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
        var d = iframeWin.document;
        d.open();
        d.write('<html id="iFrameHtml"><link href="/css/pmcontent.min.css" rel="stylesheet" /><\/head><body>' + content + '<\/body><\/html>');
        d.close();
    }
};

//TextArea
function initTextAreas() {
    $(".rtTACView").each(function () {
        $(this).text($(this).parent().parent().find("TextArea").text().replace(/\n/gi, "<br />").replace(/\r/, ""));
        $(this).html($(this).html().replace(/&lt;br \/&gt;/gi, "<br />"));
    });
    $(".rtTACEdit").hide();
}

function moveCaretToEnd(el) {
    moveCaretTo(el, el.val().length, el.val().length)
}

function moveCaretTo(el, selectionStart, selectionEnd) {
    el.focus();
    var input = el[0];
    if (input.setSelectionRange) {
        selectionStart = adjustOffset(input, selectionStart);
        selectionEnd = adjustOffset(input, selectionEnd);
        input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
        var range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else if (input.selection) {
        var range = input.createRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
    else {
        el.val(el.val());
    }
};

function adjustOffset(el, offset) {
    var val = el.value, newOffset = offset;
    if (val.indexOf("\r\n") > -1) {
        var matches = val.replace(/\r\n/g, "\n").slice(0, offset).match(/\n/g);
        newOffset += matches ? matches.length : 0;
    }
    return newOffset;
}