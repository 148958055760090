/*************************************
- The (read) 'more' and (read) 'less' links on the end of some lengthy sentences
**************************************/

var readToggleButton = {};

readToggleButton.bind = function () {
    for (var i = 0; i < readToggleButton.readMoreBtn.length; i++) {
        readToggleButton.showText(i);
    }

    for (var i = 0; i < readToggleButton.readLessBtn.length; i++) {
        readToggleButton.hideText(i);
    }
};

readToggleButton.showText = function (i) {
    $(readToggleButton.readMoreBtn[i])
        .off("click.show")
        .on("click.show", function (e) {
            e.preventDefault();

            readToggleButton.innerSpan = this.parentNode;
            readToggleButton.outerSpan = readToggleButton.innerSpan.parentNode;
            readToggleButton.label = readToggleButton.outerSpan.parentNode;

            readToggleButton.textMin = readToggleButton.label.getElementsByClassName(
                readToggleButton.moreTextMinClass
            )[0];
            readToggleButton.textMax = readToggleButton.label.getElementsByClassName(
                readToggleButton.moreTextMaxClass
            )[0];

            readToggleButton.textMin.classList.add("hidden");
            readToggleButton.textMax.classList.add("show");
        });
};

readToggleButton.hideText = function (i) {
    $(readToggleButton.readLessBtn[i])
        .off("click.hide")
        .on("click.hide", function (e) {
            e.preventDefault();

            readToggleButton.innerSpan = this.parentNode;
            readToggleButton.outerSpan = readToggleButton.innerSpan.parentNode;
            readToggleButton.label = readToggleButton.outerSpan.parentNode;

            readToggleButton.textMin = readToggleButton.label.getElementsByClassName(
                readToggleButton.moreTextMinClass
            )[0];
            readToggleButton.textMax = readToggleButton.label.getElementsByClassName(
                readToggleButton.moreTextMaxClass
            )[0];

            readToggleButton.textMin.classList.remove("hidden");
            readToggleButton.textMax.classList.remove("show");
        });
};

readToggleButton.init = function () {
    if (document.getElementsByClassName("more-text__button-show").length > 0) {
        readToggleButton.moreTextMinClass = "more-text__min";
        readToggleButton.moreTextMaxClass = "more-text__max";

        readToggleButton.readMoreBtn = document.getElementsByClassName(
            "more-text__button-show"
        );
        readToggleButton.readLessBtn = document.getElementsByClassName(
            "more-text__button-hide"
        );

        readToggleButton.bind();
    }
};

document.addEventListener("DOMContentLoaded", function () {
    readToggleButton.init();
});