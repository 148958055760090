//Setup DOB Controls
function parentId(senderId) {
    return senderId.replace("_Year", "").replace("_Month", "").replace("_Day", "").replaceAll("$", "_");
}

//function updateDroplistDays(sender, eventArgs) { 
//    var senderId = parentId(sender._uniqueId);
//    var day = $find(senderId + "_Day");
//    var month = $find(senderId + "_Month");
//    var year = $find(senderId + "_Year");
//    day.trackChanges();

//    var daySelectedItem = day.get_selectedItem();
//    var monthSelectedItem = month.get_selectedItem();
//    var yearSelectedItem = year.get_selectedItem();

//    var dIM = daysInMonth(monthSelectedItem.get_value(), yearSelectedItem.get_value());
//    if (daySelectedItem != undefined) { if (daySelectedItem.get_value() > dIM) { day.getItem(0).set_selected(true); } }

//    var Item29 = day.findItemByValue(29);
//    var Item30 = day.findItemByValue(30);
//    var Item31 = day.findItemByValue(31);

//    if (Item29 != undefined) { Item29.set_visible(dIM >= 29); }
//    if (Item30 != undefined) { Item30.set_visible(dIM >= 30); }
//    if (Item31 != undefined) { Item31.set_visible(dIM >= 31); }

//    day.commitChanges();
//    updateValue(sender, eventArgs);
//}

function updateValue(sender, eventArgs) {
    var senderId = parentId(sender._uniqueId);
    var control = document.getElementById(senderId);
    if (control.yearValue() == -1 && control.monthValue() == -1 && control.dayValue() == -1) {
        control.value = "-1";
        $("#" + senderId).parents('.' + $('#' + senderId).attr('data-parent')).removeClass($('#' + senderId).attr('data-active-class'));
    }
    else if (control.yearValue() != "" && control.monthValue() != "" && control.dayValue() != "" && control.yearValue() != -1 && control.monthValue() != -1 && control.dayValue() != -1) {
        var y = control.yearValue();
        var m = (parseInt(control.monthValue()) > 9 ? "" : "0") + parseInt(control.monthValue());
        var d = (parseInt(control.dayValue()) > 9 ? "" : "0") + parseInt(control.dayValue());
        control.value = y + "-" + m + "-" + d;
        $("#" + senderId).parents('.' + $('#' + senderId).attr('data-parent')).addClass($('#' + senderId).attr('data-active-class'));
    }
    else {
        control.value = "";
        $("#" + senderId).parents('.' + $('#' + senderId).attr('data-parent')).removeClass($('#' + senderId).attr('data-active-class'));
    }
    if ((control.FiredValidation == 'true') || (control.yearValue() != "" && control.monthValue() != "" && control.dayValue() != "")) {
        control.FiredValidation = 'true';
        Page_ClientValidateByControl(parentId(sender._uniqueId));
    }
}

//function updateComboBoxDays(sender, eventArgs) {
//    updateComboBoxDaysAction(sender._uniqueId);
//    updateValue(sender, eventArgs);
//}

//function updateComboBoxDaysAction(senderId) {

//    senderId = parentId(senderId);
//    var jqDay = $("#" + senderId + "_Day");
//    jqDay.removeClass('day-error');
//    jqDay.removeClass('day-error--custom');

//    var day = $find(senderId + "_Day");
//    var month = $find(senderId + "_Month");
//    var year = $find(senderId + "_Year");
//    day.trackChanges();

//    var daySelectedItem = day.get_selectedItem();
//    var monthSelectedItem = month.get_selectedItem();
//    var yearSelectedItem = year.get_selectedItem();

//    var dIM = daysInMonth(getValue(month), getValue(year));
//    if ((getValue(day) == -1 && getValue(month) != -1 && getValue(year) != -1) || getValue(day) > dIM) {
//        day.get_items().getItem(0).set_selected(true);
//        if (typeof day.set_text !== "undefined") { day.set_text("??"); }
//        jqDay.addClass('day-error');
//        jqDay.addClass('day-error--custom');
//    }

//    var Item29 = day.findItemByValue(29);
//    var Item30 = day.findItemByValue(30);
//    var Item31 = day.findItemByValue(31);

//    if (Item29 != undefined) { Item29.set_visible(dIM >= 29); }
//    if (Item30 != undefined) { Item30.set_visible(dIM >= 30); }
//    if (Item31 != undefined) { Item31.set_visible(dIM >= 31); }

//    //if (dIM >= 29) {
//    //    if (Item29 == undefined) {
//    //        var newItem = new Telerik.Web.UI.RadComboBoxItem();
//    //        newItem.set_text("29");
//    //        newItem.set_value("29");
//    //        day.get_items().add(newItem);
//    //    }
//    //}
//    //else {
//    //    if (Item29 != undefined) { day.get_items().remove(Item29); }
//    //}

//    //if (dIM >= 30) {
//    //    if (Item30 == undefined) {
//    //        var newItem = new Telerik.Web.UI.RadComboBoxItem();
//    //        newItem.set_text("30");
//    //        newItem.set_value("30");
//    //        day.get_items().add(newItem);
//    //    }
//    //}
//    //else {
//    //    if (Item30 != undefined) { day.get_items().remove(Item30); }
//    //}

//    //if (dIM >= 31) {
//    //    if (Item31 == undefined) {
//    //        var newItem = new Telerik.Web.UI.RadComboBoxItem();
//    //        newItem.set_text("31");
//    //        newItem.set_value("31");
//    //        day.get_items().add(newItem);
//    //    }
//    //}
//    //else {
//    //    if (Item31 != undefined) { day.get_items().remove(Item31); }
//    //}
//    day.commitChanges();
//}

function getValue(item) {
    if (typeof item.get_text !== "undefined") {
        var listItem = item.findItemByText(item.get_text());
        if (listItem) { return listItem.get_value(); }
        return "";
    }
    if (item.get_selectedItem()) { return item.get_selectedItem().get_value(); }
    return ""
}

function daysInMonth(month, year) {
    if (month == undefined || year == undefined) { return 31; }
    if (isNaN(month)) { month = getValue(month); }
    if (isNaN(year)) { year = getValue(year); }
    if ((month == '' || month == -1) || (year == '' || year == -1)) { return 31; }
    return new Date(year, month, 0).getDate();
}

function dateControlSetup() {

    $('.date-droplist,.date-combobox').each(function () {
        document.getElementById(this.id).getValue = function () {
            if (this.isEmpty()) { return "-1"; }
            if (!this.isValid()) { return; }
            return (new Date(this.yearValue(), this.monthValue() - 1, this.dayValue()));
        };

        document.getElementById(this.id).value = "";

        document.getElementById(this.id).isValidDay = function () {
            //updateComboBoxDaysAction(this.id);
            if (this.isEmpty()) { return this.showAsValid(true); }
            if (this.dayValue() == "") { return this.showAsValid(false); }
            if (!this.isValidMonth) { return this.showAsValid(true); }
            if (!this.isValidYear) { return this.showAsValid(true); }
            if (daysInMonth(this.month(), this.year()) >= this.day()) { return this.showAsValid(false); }
            if (this.maxDate().getMonth() + 1 == this.monthValue()) { return this.showAsValid(this.maxDate().getDate() >= this.dayValue()) };
            if (this.minDate().getMonth() + 1 == this.monthValue()) { return this.showAsValid(this.minDate().getDate() <= this.dayValue()) };
            return this.showAsValid(true);
        };

        document.getElementById(this.id).isValidMonth = function () {
            //updateComboBoxDaysAction(this.id);
            if (this.isEmpty()) { return this.showAsValid(true); }
            if (this.monthValue() == "") { return this.showAsValid(false); }
            if (!this.isValidYear) { return this.showAsValid(true); } if (this.maxDate().getFullYear() == this.yearValue()) { return this.showAsValid(this.maxDate().getMonth() + 1 >= this.monthValue()) };
            if (this.minDate().getFullYear() == this.yearValue()) { return this.showAsValid(this.minDate().getMonth() + 1 <= this.monthValue()) };
            return this.showAsValid(true);
        };

        document.getElementById(this.id).isValidYear = function () {
            //updateComboBoxDaysAction(this.id);
            if (this.isEmpty()) { return this.showAsValid(true); }
            if (this.yearValue() == "") { return this.showAsValid(false); }
            if (this.yearValue() == -1) { return this.showAsValid(false); }
            return this.showAsValid(this.maxDate().getFullYear() >= this.yearValue()) && (this.minDate().getFullYear() <= this.yearValue());
        };

        document.getElementById(this.id).isValid = function () {
            //updateComboBoxDaysAction(this.id);
            if (this.isEmpty()) { return this.showAsValid(true); }
            return this.showAsValid(this.isValidYear() && this.isValidMonth() && this.isValidDay());
        };

        document.getElementById(this.id).showAsValid = function (HideError) {
            if (HideError) { this.classList.remove("RocktimeFieldErrorDate"); }
            else { this.classList.add("RocktimeFieldErrorDate"); }
            return (HideError);
        }

        document.getElementById(this.id).isEmpty = function () {
            return (this.yearValue() == "-1") && (this.monthValue() == "-1") && (this.dayValue() == "-1");
        };

        document.getElementById(this.id).maxDate = function () {
            var $field = $(this);
            var max = Date.parse($field.attr('data-maxdate'));
            if (!max) { return new Date(Date.maxValue); }
            return new Date(max);

        };

        document.getElementById(this.id).minDate = function () {
            var $field = $(this);
            var min = Date.parse($field.attr('data-mindate'));
            if (!min) { return new Date(Date.minValue); }
            return new Date(min);
        };

        document.getElementById(this.id).day = function () {
            return $find(this.id + "_Day");
        };

        document.getElementById(this.id).month = function () {
            return $find(this.id + "_Month");
        };

        document.getElementById(this.id).year = function () {
            return $find(this.id + "_Year");
        };

        document.getElementById(this.id).dayValue = function () {
            return getValue(this.day());
        };

        document.getElementById(this.id).monthValue = function () {
            return getValue(this.month());
        };

        document.getElementById(this.id).yearValue = function () {
            return getValue(this.year());
        };

    })

    $('.AutoAdvance Input, Input.AutoAdvance').blur(
        function () {
            $(this).removeClass('day-error');
            $(this).removeClass('day-error--custom');
        }
    )

    $('.AutoAdvance Input, Input.AutoAdvance').keyup(function (e) {
        if (e.keyCode < 48 && e.keyCode != 8) { return }
        if (e.keyCode > 105) { return }
        var $field = $(this);
        var $dataOptions = $(this).closest('.AutoAdvance');
        var $previousField = $('#' + $dataOptions.attr('data-autoadvance-previous') + ' Input, Input#' + $dataOptions.attr('data-autoadvance-previous'));
        var $nextField = $('#' + $dataOptions.attr('data-autoadvance-next') + ' Input, Input#' + $dataOptions.attr('data-autoadvance-next'));
        var maxchars = parseInt($dataOptions.attr('data-autoadvance-maxchars'), 10);
        var numbersOnly = ($dataOptions.attr('data-autoadvance-numbersonly') == "true");
        var isComboBox = ($dataOptions.attr('data-autoadvance-iscombobox') == "true");
        var allowcomboboxfreetext = ($dataOptions.attr('data-autoadvance-allowcomboboxfreetext') == "true");
        var fieldVar = $field.val().trim();

        if (isComboBox) {
            var Dl = $find(this.id.replace("_Input", ""));
            if (Dl) {
                Dl.hideDropDown();

                if (!allowcomboboxfreetext && fieldVar != '1') {
                    maxchars = 10;
                    var result = checkComboBoxValue(Dl);
                    if (result == -1) { return };
                    if (result != 0) { fieldVar = result; maxchars = fieldVar.length; }
                }
            }
        }

        if (numbersOnly && isNaN(fieldVar)) {
            $field.val(fieldVar.substring(0, previousmaxchars - 1));
            return
        }

        if (e.keyCode == 8) {
            if ($dataOptions.attr('data-autoadvance-previous') != undefined) {
                if ($dataOptions.attr('isEmpty') == 'true' && fieldVar == '') {
                    var $previousDataOptions = $previousField.closest('.AutoAdvance');
                    var previousmaxchars = parseInt($previousDataOptions.attr('data-autoadvance-maxchars'), 10);
                    $previousDataOptions.attr('isEmpty', false);
                    $previousField.focus();
                    $previousField.val($previousField.val().substring(0, previousmaxchars - 1));
                }
                $dataOptions.attr('isEmpty', (fieldVar == ''));
            }
            return;
        }

        $dataOptions.attr('isEmpty', true);
        if (fieldVar.length >= maxchars) {
            if ($dataOptions.attr('data-autoadvance-next') != undefined) {
                var $nextDataOptions = $nextField.closest('.AutoAdvance');
                $nextField.focus();
                $nextField.select();
                if (!fieldVar.substring(maxchars) == "") { $nextField.val(fieldVar.substring(maxchars)); }
                $nextDataOptions.attr('isEmpty', false);
            }
            $field.val(fieldVar.substring(0, maxchars));
            return;
        }
    });

    function checkComboBoxValue(Dl) {

        var textInTheCombo = Dl.get_text();
        for (var i = 0; i < Dl.get_items().get_count(); i++) {
            var item = Dl.get_items().getItem(i);
            if (item.get_text() == textInTheCombo || item.get_text() == textInTheCombo.toTitleCase() || item.get_value() == textInTheCombo) {
                Dl.trackChanges();
                Dl.set_text(item.get_text());
                Dl.commitChanges();
                return item.get_text();
            }
            if (item.get_text().startsWith(textInTheCombo) || item.get_text().startsWith(textInTheCombo.toTitleCase()) || item.get_value().startsWith(textInTheCombo)) {
                return 0;
            }
        }

        Dl.showDropDown();
        Dl.trackChanges();
        Dl.get_items().getItem(0).set_selected(true);
        Dl.set_text("");
        var inputElement = Dl.get_inputDomElement();
        inputElement.focus();
        Dl.commitChanges();
        return -1;
    }

}